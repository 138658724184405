<script setup>

import { useNuxtApp } from "#app";

const props = defineProps({
  noBanner: Boolean,
  opened: Boolean,
  includesBrand: String,
  bannerSlotName: {
    type: String,
    default: () => 'portal2-banner-1336x300'
  },
  hideOnMobile: {
    type: Boolean,
    default: () => false
  }
})

const emits = defineEmits(['searchResultsCountChange'])
const { $triggerEvent } = useNuxtApp()

const route = useRoute();
const searchOpenOnStartup = ref(typeof route.query.searchopen !== 'undefined');
const refDrawer = ref();
const drawerVisible = ref(false);
const backendStore = useBackendStore();
const { isEmbed } = backendStore;

const drawerOpen = () => {
  drawerVisible.value = true;
  refDrawer.value.open();
}

const resultsCountChange = (v) => {
  emits('searchResultsCountChange',v);
  if(searchOpenOnStartup.value) {
    drawerOpen();
    searchOpenOnStartup.value = false;
  }
}

onMounted(() => {
  if (props.opened && !isEmbed) {
    setTimeout(() => drawerOpen(), 200);
  }
})

</script>

<template>
  <div>
    <BannerMain :includesBrand="includesBrand" :bannerName="bannerSlotName"  v-if="!noBanner" />
    <div :class="{'hidden 2xl:block' : hideOnMobile}" class="relative">
      <Brands @searchClick="drawerOpen" :visible="!drawerVisible" />
      <Drawer ref="refDrawer"
              @beforeClose="drawerVisible = false"
              withEscHandler
              overlay>
        <ClientOnly>
          <SearchParameters
            :useUrlQuery="route.name === 'search'"
            :clearSearch="route.name === 'index'"
            @resultsCountChange="resultsCountChange"
          />
        </ClientOnly>
      </Drawer>
    </div>
  </div>
</template>

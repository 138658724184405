<script setup>
import { useParameters } from "~/composables/Parameters";
const backendStore = useBackendStore();

const props = defineProps({
  useUrlQuery: Boolean,
  clearSearch: Boolean
})

const emits = defineEmits(['resultsCountChange']);
const route = useRoute();
const isMounted = ref(false);
const searcher = useSearch();
const {filterOptionsEventNames, analyticsLayer} = useAnalytics();
const {getDefaults, getState, getPower, getSeats, getDefaultPrices, getProductionYears} = useParameters();
const {getDictionaries, getDictionariesKeys} = useDictionaries();
const breakpoints = useBreakpoints();

const dropDowns = ref([]);

const addToDropDowns = (el) => dropDowns.value.push(el);

const pricesBoundaries = {...getDefaultPrices()}

const values = ref({
  left: getDefaults('priceMin', props.useUrlQuery),
  right: getDefaults('priceMax', props.useUrlQuery, pricesBoundaries.max)
})

const clearForm = () => {
  dropDowns.value.forEach(el => el.reset());
  values.value.left = null;
  values.value.right = null;
}

const allBrands = await useBackend().getBrands();
const brands = computed(() => allBrands.data.filter(brand => brand.carsCount))
const models = ref([]);
const productionYearOptions = ref(await getProductionYears());

const options = ref({
  brand: brands,
  state: getState(),
  body: getDictionariesKeys().body.toMap(true),
  model: [],
  productionYear: productionYearOptions,
  seats: getSeats(),
  power: getPower(),
  fuel: getDictionariesKeys().fuel.toMap(true),
  gearbox: getDictionariesKeys().gearbox.toMap(true),
  drive: getDictionariesKeys().drive.toMap(true),
})

if (props.clearSearch) {
  useParameters().storedQuery.value = {}
}

const modelsFromQuery = getDefaults('model', props.useUrlQuery);

const search = ref({
  body: getDefaults('body', props.useUrlQuery),
  state: getDefaults('state', props.useUrlQuery),
  productionYear: getDefaults('productionYear', props.useUrlQuery),
  brand: getDefaults('brand', props.useUrlQuery),
  model: modelsFromQuery,
  fuel: getDefaults('fuel', props.useUrlQuery),
  gearbox: getDefaults('gearbox', props.useUrlQuery),
  drive: getDefaults('drive', props.useUrlQuery),
  powerMin: getDefaults('powerMin', props.useUrlQuery),
  powerMax: getDefaults('powerMax', props.useUrlQuery),
  seats: getDefaults('seats', props.useUrlQuery),
})

const query = ref({})

const performSearch = () => {
  useParameters().storedQuery.value = query.value;
  const newQuery = { ...query.value };
  navigateTo({ path: '/wyszukaj', query: newQuery }, { external: true });
}

const priceMin = computed(() => values.value.left);
const priceMax = computed(() => values.value.right);
const results = ref(0);

let preSearchTimeoutHandle = null;
const preSearchCount = () => {
  clearTimeout(preSearchTimeoutHandle);
  preSearchTimeoutHandle = setTimeout(async () => {
  query.value.priceMin = values.value.left;
  query.value.priceMax = values.value.right;
  const data = await searcher.search(query.value, 1, true);
  results.value = data.data.totalCount;
  emits('resultsCountChange', results.value);
  }, 100)
}

const compare = (base, toCompare, less) => {
  if (search.value[toCompare][0] && base[0]) {
    base = parseInt(toRaw(base)[0]);
    const value = parseInt(search.value[toCompare][0]);

    if (less && base < value || !less && base > value) {
      search.value[toCompare] = [];
    }
  }
}
const loadModelsByBrand = async (selectedBrand) => {
  try {
    const response = await useBackend().getModels({ brand: selectedBrand });
    models.value = response.data.sort((a, b) => a.modelName.localeCompare(b.modelName));
    let model = toRaw(route.query.model);
    if(model && model.pop === undefined) model = [model];
    search.value.model = model || [];
  } catch (error) {
    console.error('Błąd podczas pobierania modeli:', error);
  }
}

const onBrandChange = async (v, isReset) => {
  prepareSearchQuery('brand', v, isReset);
  const selectedBrands = Object.values(search.value.brand || {});
  if (selectedBrands.length === 1) {
    const selectedBrand = selectedBrands[0];
    await loadModelsByBrand(selectedBrand);
  } else {
    models.value = [];
  }
};

const prepareSearchQuery = async (type, value, isReset, preCount = true) => {
  value = toRaw(value);
  query.value = {...query.value, priceMin: priceMin.value, priceMax: priceMax.value};

  if (type.startsWith('power')){
    compare(value, type === 'powerMin' ? 'powerMax' : 'powerMin', type === 'powerMax');
  }

  if (!isReset && isMounted.value) {
    analyticsLayer({
      'event': filterOptionsEventNames[type],
      'filter_type': Object.values(value).join(",")
    });
  }

  switch (type) {
    case 'fuel':
    case 'gearbox':
    case 'drive':
    case 'body':
      if(typeof value.pop !== 'undefined') {
        let values = [];
        value.forEach(v => {
          values = values.concat(getDictionaries()[type][v])
        });
        query.value[type] = values.unique();
      } else {
        query.value[type] = getDictionaries()[type][value] || []
      }
      break;
    default:
      query.value[type] = value;
  }
  if(preCount) preSearchCount();
}

const setSearchQuery = () => {
  Object.keys(search.value).forEach(key=> {
    const value = search.value[key];
    prepareSearchQuery(key, value, false, false)
  });
  if(search.value.brand.length === 1) {
     loadModelsByBrand(search.value.brand)
  }
}

const refreshCounts = async () => {
  if(!isMounted.value) return;
  query.value = {...query.value, priceMin: priceMin.value, priceMax: priceMax.value};
  preSearchCount();
}

onMounted(async () => {
  setSearchQuery();
  isMounted.value = true;
  await refreshCounts();
})

</script>

<template>
  <div class="relative safari-fix-position">
    <div class="mb-2">Parametry techniczne</div>
    <div class="grid grid-cols-4 md:grid-cols-8 xl:grid-cols-16 gap-2 w-full">
      <InputDropDown :ref="addToDropDowns"
                     :options="options.state"
                     v-model="search.state"
                     @change="(v, isReset) => prepareSearchQuery('state',v, isReset)" label="Stan" class="col-span-2" />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.body"
                     v-model="search.body"
                     @change="(v, isReset) => prepareSearchQuery('body',v, isReset)" label="Nadwozie" class="col-span-2" multiple />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.brand"
                     v-model="search.brand"
                     :map="['name','name']"
                     @change="onBrandChange" label="Marka" class="col-span-2" multiple with-search />

      <InputDropDown :ref="addToDropDowns"
                     :options="models"
                     v-model="search.model"
                     :map="['modelName','modelName']"
                     @change="(v, isReset) => prepareSearchQuery('model',v,isReset)" label="Model"
                     :inputDisabled="!search.brand.length || search.brand.length > 1" :class="{'pointer-events-none' : !search.brand.length || search.brand.length > 1 }" class="col-span-2" multiple with-search />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.productionYear"
                     v-model="search.productionYear"
                     @change="(v, isReset) => prepareSearchQuery('productionYear',v,isReset)"
                     label="Rok produkcji" class="col-span-2" multiple />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.fuel"
                     v-model="search.fuel"
                     @change="(v, isReset) => prepareSearchQuery('fuel',v, isReset)"
                     label="Paliwo" class="col-span-2" multiple />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.gearbox"
                     v-model="search.gearbox"
                     @change="(v, isReset) => prepareSearchQuery('gearbox',v, isReset)"
                     label="Skrzynia biegów" class="col-span-2" multiple />

      <InputDropDown :ref="addToDropDowns"
                     :options="options.drive"
                     v-model="search.drive"
                     @change="(v, isReset) => prepareSearchQuery('drive',v, isReset)"
                     label="Napęd" class="col-span-2" multiple />

      <div class="hidden">
        <InputDropDown :ref="addToDropDowns"
                      :options="options.seats"
                      v-model="search.seats"
                      @change="(v, isReset) => prepareSearchQuery('seats',v, isReset)"
                      label="Miejsc" class="col-span-1" multiple />

        <InputDropDown :ref="addToDropDowns"
                      :options="options.power"
                      v-model="search.powerMin"
                      @change="(v, isReset) => prepareSearchQuery('powerMin',v, isReset)"
                      label="Moc od" class="col-span-1" />

        <InputDropDown :ref="addToDropDowns"
                     :options="options.power"
                     v-model="search.powerMax"
                     @change="(v, isReset) => prepareSearchQuery('powerMax',v, isReset)"
                     label="Moc do" class="col-span-1" />
      </div>
    </div>

    <div class="flex flex-wrap justify-between items-center mt-4">
      <div class="md:w-full md:mb-4 lg:mb-0">Cena</div>
      <div class="hidden md:flex font-extralight gap-1 items-baseline lg:order-3">
        <!--        <div class="text-sm">Twoja rata:</div>-->
        <!--        <div class="text-azure">999 - 9 999 zł</div>-->
      </div>
      <InputRange class="w-full lg:w-auto lg:order-2 lg:grow lg:-mt-4 lg:mx-6"
                  :min="pricesBoundaries.min"
                  :max="pricesBoundaries.max"
                  :step="1000"
                  v-model:values="values"
                  fields
                  :compact="!breakpoints.breakpoint.value"
                  @changed="refreshCounts"
      />
    </div>

    <div class="shadow-box-top -mx-4 sm:-mx-7 -mb-6 px-4 md:px-8 pt-4 pb-4">
      <div class="flex justify-between items-center gap-2 w-full md:justify-end">
        <ButtonPlain tiny class="text-gray-700 grow md:grow-0" @click="clearForm">
          <template v-slot:default>Wyczyść wyszukiwanie</template>
          <template v-slot:rightSlot>
            <IconTrashCan class="!h-4 !w-4 stroke-gray-700" />
          </template>
        </ButtonPlain>
        <ButtonRounded type="gray" class="grow md:grow-0" @click="performSearch" fit>Pokaż samochody: {{ results }}</ButtonRounded>
      </div>
    </div>
  </div>
</template>

<script setup>
  const backendStore = useBackendStore();
  const { isEmbed } = backendStore;
</script>

<template>
  <div
    :class="isEmbed ? 'xl:grid-cols-4 xl:col-span-4' : 'xl:grid-cols-3 xl:col-span-3'"
    class="flex md:grid md:grid-cols-2 gap-6 flex-wrap col-span-2 h-fit"
  >
    <slot />
  </div>
</template>
